<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '350px' }"
    header="Sub Grupo Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="nombre"
            ><strong>NOMBRE SUB GRUPO: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre"
            v-model="data_subgrupo.nombre"
            required="true"
            autofocus
            @keydown.enter="saveSubGrupo"
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12">
          <label for="grupo"
            ><strong>GRUPO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="grupo"
            v-model="grupoSelected"
            :options="grupos"
            optionLabel="nombre"
            placeholder="Seleccione un grupo..."
            :filter="true"
          />
          <small class="p-invalid" v-if="errors.grupo_id">{{
            errors.grupo_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12">
          <label for="estado"
            ><strong>Estado: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_subgrupo.estado"
            :disabled="!data_subgrupo.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>Campos Requeridos: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="saveSubGrupo"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import SubGrupoService from "@/service/SubGrupoService";
import GrupoService from "@/service/GrupoService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    subgrupo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      mostrarModal: this.show,
      data_subgrupo: this.subgrupo,
      enviado: false,
      estados: [
        { label: "ACTIVO", value: 1 },
        { label: "INACTIVO", value: 0 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      grupos: [],
      grupoSelected: {},
    };
  },
  subGrupoService: null,
  grupoService: null,
  created() {
    this.subGrupoService = new SubGrupoService();
    this.grupoService = new GrupoService();
  },
  mounted() {
    this.cargarGrupos();
  },
  methods: {
    cargarGrupos() {
      this.grupoService.getGrupoAll().then((data) => {
        this.grupos = data.grupos;
      });
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    saveSubGrupo() {
      this.errors = {};
      this.enviado = true;
      //editar subgrupo
      if (this.data_subgrupo.id) {
        let grupo_enviar = {
          ...this.data_subgrupo,
          estado: this.data_subgrupo.estado.value,
          grupo_id: this.grupoSelected ? this.grupoSelected.id : null,
        };
        this.subGrupoService.updateSubGrupo(grupo_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PUNTO DE VENTA
        let tmp = {
          ...this.data_subgrupo,
          estado: this.data_subgrupo.estado.value,
          grupo_id: this.grupoSelected ? this.grupoSelected.id : null,
        };
        this.subGrupoService.sendSubGrupo(tmp).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_subgrupo = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListado", data.subgrupo);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    subgrupo(val) {
      this.data_subgrupo = val;
      
      this.grupoSelected = this.grupos.find(
        (grupo) => grupo.id == this.data_subgrupo.grupo_id ?? 1
      );
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>