import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ProcedenciaService {
  async getProcedenciaAll() {
    const procedencias = await fetchWrapper.get(`${ruta}/procedencias`);
    return procedencias;
  }

  async sendProcedencia(datos) {
    return await fetchWrapper.post(`${ruta}/procedencias`, datos);
  }

  async deleteProcedencia(id) {
    return await fetchWrapper.delete(`${ruta}/procedencias/${id}`);
  }

  async updateProcedencia(procedencia) {
    const actualizado = await fetchWrapper.put(
      `${ruta}/procedencias/` + procedencia.id,
      procedencia
    );
    return actualizado;
  }
}