import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class SubGrupoService {
  async getSubGrupoAll() {
    const grupos = await fetchWrapper.get(`${ruta}/subgrupos`);
    return grupos;
  }

  async sendSubGrupo(datos) {
    return await fetchWrapper.post(`${ruta}/subgrupos`, datos);
  }

  async deleteSubGrupo(id){
    return await fetchWrapper.delete(`${ruta}/subgrupos/${id}`);
  }

  async updateSubGrupo(subgrupo) {
    const actualizado = await fetchWrapper.put(
      `${ruta}/subgrupos/` + subgrupo.id,
      subgrupo
    );
    return actualizado;
  }
}